import axios from "axios";
import { v4 as uuidv4 } from "uuid";
import { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import SkeletonStory from "../Skeletons/SkeletonStory";
import CardStory from "../StoryScreens/CardStory";
import NoStories from "../StoryScreens/NoStories";
import Pagination from "./Pagination";
import "../../Css/Home.css";

const Home = () => {
  const search = useLocation().search;
  const searchKey = new URLSearchParams(search).get("search");
  const [stories, setStories] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const [page, setPage] = useState(1);
  const [pages, setPages] = useState(1);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth); // Track screen size

  // Effect to fetch stories
  useEffect(() => {
    const getStories = async () => {
      setLoading(true);

      try {
        const { data } = await axios.get(
          `/story/getAllStories?search=${searchKey || ""}&page=${page}`
        );

        if (searchKey) {
          navigate({
            pathname: "/",
            search: `?search=${searchKey}${page > 1 ? `&page=${page}` : ""}`,
          });
        } else {
          navigate({
            pathname: "/",
            search: `${page > 1 ? `page=${page}` : ""}`,
          });
        }

        setStories(data.data);
        setPages(data.pages);
        setLoading(false);
      } catch (error) {
        setLoading(true);
      }
    };
    getStories();
  }, [setLoading, search, page, navigate]);

  // Effect to track screen size
  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    // Add event listener for resize
    window.addEventListener("resize", handleResize);

    // Cleanup event listener on unmount
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // Adjust the number of stories to display based on screen size
  let pageSize = 18; // Default value for max stories

  if (screenWidth >= 2700) {
    pageSize = 18; // Max 18 cards (2 rows: 9 cards per row)
  } else if (screenWidth >= 2400) {
    pageSize = 16; // Max 16 cards (2 rows: 8 cards per row)
  } else if (screenWidth >= 2100) {
    pageSize = 21; // Max 21 cards (3 rows: 7 cards per row)
  } else if (screenWidth >= 1800) {
    pageSize = 18; // Max 18 cards (3 rows: 6 cards per row)
  } else if (screenWidth >= 1500) {
    pageSize = 15; // Max 15 cards (3 rows: 5 cards per row)
  } else if (screenWidth >= 1200) {
    pageSize = 12; // Max 12 cards (3 rows: 4 cards per row)
  } else if (screenWidth >= 900) {
    pageSize = 9; // Max 9 cards (3 rows: 3 cards per row)
  } else if (screenWidth >= 800) {
    pageSize = 8; // Max 8 cards (4 rows: 2 cards per row)
  } else if (screenWidth >= 600) {
    pageSize = 6; // Max 6 cards (3 rows: 2 cards per row)
  } else if (screenWidth >= 400) {
    pageSize = 4; // Max 4 cards (4 rows: 1 card per row)
  } else {
    pageSize = 1; // Max 2 cards (1 row: 1 card per row)
  }

  // Adjust the number of stories based on the page and pageSize
  const displayedStories = stories.slice(0, pageSize);

  return (
    <div className="Inclusive-home-page">
      {loading ? (
        <div className="skeleton_emp">
          {[...Array(6)].map(() => {
            return (
              // theme dark :> default : light
              <SkeletonStory key={uuidv4()} />
            );
          })}
        </div>
      ) : (
        <div>
          <div className="story-card-wrapper">
            {displayedStories.length !== 0 ? (
              displayedStories.map((story) => {
                return <CardStory key={uuidv4()} story={story} />;
              })
            ) : (
              <NoStories />
            )}
          </div>

          <Pagination page={page} pages={pages} changePage={setPage} />
        </div>
      )}
      <br />
    </div>
  );
};

export default Home;
